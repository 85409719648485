<template>
  <div class="time-select">
    <div class="buttons">
      <button class="btn active">Сегодня</button>
      <button class="btn">Вчера</button>
      <button class="btn">7 дней</button>
      <button class="btn">31 день</button>
    </div>

    <div class="calendar">
      <calendar-icon class="calendar-icon" />

      <div class="calendar-timelapse-text">5.12.19 00:00 — 5.12.19 23:59</div>
      <triangle-icon class="calendar-timelapse-select" />
    </div>
  </div>
</template>

<script>
import CalendarIcon from "!vue-svg-loader!../images/calendar-icon.svg";
import TriangleIcon from "!vue-svg-loader!../images/triangle-icon.svg";

export default {
  components: {
    CalendarIcon,
    TriangleIcon
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/variables";

.time-select {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn {
  font-family: Formular;
  font-weight: bold;
  font-size: 12px;
  background-color: #fff;
  color: $color-primary;
  border-radius: 14px;
  padding: 7.5px 16px;
  border: none;
  margin: 0 4px;
  min-width: 75px;
  cursor: pointer;
}

.btn:first-child {
  margin-left: 0;
}

.btn.active {
  background-color: $color-primary;
  color: #fff;
}

.calendar {
  margin-left: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.calendar-icon {
  width: 16px;
  height: 16px;
}

.calendar-timelapse-text {
  font-family: Formular;
  font-weight: normal;
  font-size: 12px;
  color: #99a2ad;
  margin-left: 10px;
}

.calendar-timelapse-select {
  position: relative;
  top: 1px;
  width: 8px;
  height: 8px;
  margin-left: 5px;
}
</style>
