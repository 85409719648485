import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
  {
    path: '/',
    component: () => import('@/views/Index'),
    children: [
      {
        path: '',
        name: 'main',
        component: () => import('@/views/Stores/Stores'),
      },

      {
        path: '/:store/orders',
        name: 'store',
        component: () => import('@/views/Orders/Orders'),
      },

      {
        path: '/:store/:order/products',
        name: 'order',
        component: () => import('@/views/Products/Products'),
      },
    ],
  },

  {
    path: '/loading',
    name: 'loading',
    component: () => import('@/views/Loading'),
  },
];

const router = new Router({
  mode: 'history',
  linkActiveClass: 'active',
  base: process.env.BASE_URL,
  routes,
});

export default router;
