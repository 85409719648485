import Vue from 'vue';
import Vuex from 'vuex';

import stores from './modules/stores';
import orders from './modules/orders';
import products from './modules/products';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { stores, orders, products },
});
