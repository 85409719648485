import { fetchProfile } from "./guards/fetchProfile";
import $profile from "./store/profile";

export function connect({ store, router } = {}) {
  if (store) {
    store.registerModule("$profile", $profile);
  }

  if (router) {
    router.beforeEach(fetchProfile);
  }
}
