import { bus } from 'shared/core';

class MainEvent {
  _store;

  init(store) {
    this._store = store;
    bus.on('order', this.onOrder);
  }

  onOrder = order => {
    this._store.dispatch('orders/addValueAction', order);
  };
}

export default new MainEvent();
