<template>
  <div
    data-drag-over
    @click="onClick"
    @drop="onDrop"
    @dragenter="onDragEnter"
    @dragover="onDragOver"
    @dragend="onDragEnd"
    @dragleave="onDragLeave"
    :class="{
      'drop-area': true,
      enter: enter,
      'has-previews': hasPreviews,
      hoverable: !hasPreviews
    }"
  >
    <div v-if="!hasPreviews">
      Кликните или перетащите сюда {{ formatAcceptedFiles }} в формате {{ getAcceptedTypes }},
      максимальный размер {{ formatSizeToMegabytes }} MB
    </div>

    <div v-if="hasPreviews" class="previews">
      <div
        v-for="file in filesWithPreview"
        :key="file.id"
        :style="{ 'background-image': `url('${file.preview}')` }"
        :class="{ preview: true, 'col-2-4': filesWithPreview.length > 1 }"
      >
        <div
          @mouseenter="onMouseEnterPreview"
          @mouseleave="onMouseLeavePreview"
          @click="onClickPreview($event, file)"
          class="preview__hover"
        >
          <div
            @mouseenter="onMouseEnterPreview"
            @mouseleave="onMouseLeavePreview"
            class="preview__hover-toolbar"
          >
            <crop-icon
              v-if="isShowable(file)"
              @click="onClickStartCrop(file)"
              :class="{ 'preview-icon': true, 'preview-icon_crop': true }"
            />

            <zoom-icon
              v-if="isShowable(file)"
              @click="onClickStartShow(file)"
              :class="{ 'preview-icon': true, 'preview-icon_zoom': true, active: previewable }"
            />
            <close-icon @click="onClickRemove(file)" class="preview-icon" />
          </div>
        </div>
      </div>
    </div>

    <input
      @change="onFileInputChange"
      :class="{ 'file-input': true, hidden: hasPreviews }"
      type="file"
      :multiple="isMultiple"
      :accept="getAcceptedTypes"
    />

    <div :class="{ 'progress-bar': true, visible: canShowProgressBar }">
      <div class="progress-bar__load" :style="{ width: `${this.computeProgressBar}` }"></div>
    </div>

    <cropper
      v-if="croppedFile"
      :exit="onCropperExit"
      :accept="onCropperAccept"
      :value="croppedFileImage"
    />

    <images-preview
      v-if="showedFile"
      :exit="onClickStopShow"
      :current="showedFile.preview"
      :images="allPreviews"
    />
  </div>
</template>

<script>
import ImagesPreview from "./ImagesPreview";
import Cropper from "./Cropper";
import CloseIcon from "!vue-svg-loader!shared/modules/personal/images/close-icon.svg";
import CropIcon from "!vue-svg-loader!shared/modules/personal/images/crop-icon.svg";
import ZoomIcon from "!vue-svg-loader!shared/modules/personal/images/zoom-icon.svg";
import { api } from "shared/core";

let nextFileId = 0;
const imageRegex = /(jpg|png|gif|jpeg)/;

const LOAD_STATUSES = {
  INACTIVE: "INACTIVE",
  LOADING: "LOADING",
  FINISH: "FINISH",
  ERROR: "ERROR"
};

export default {
  components: {
    CloseIcon,
    ZoomIcon,
    CropIcon,
    Cropper,
    ImagesPreview
  },
  props: {
    allowedTypes: {
      type: Array,
      required: false,
      default: () => ["png", "jpg", "jpeg"]
    },
    allowedSize: {
      type: Number,
      required: false,
      // TODO: reset to 2048
      default: 512 * 100
    },
    imageMinWidth: {
      type: Number,
      required: false,
      default: 128
    },
    imageMinHeight: {
      type: Number,
      required: false,
      default: 128
    },
    imageMaxWidth: {
      type: Number,
      required: false,
      default: 0
    },
    imageMaxHeight: {
      type: Number,
      required: false,
      default: 0
    },
    allowedCount: {
      type: Number,
      required: false,
      // TODO: reset to 1
      default: 4
    }
  },
  data() {
    return {
      identifier: `[data-drag-over]`,
      enter: false,
      queue: [],
      queueStart: false,
      files: [],
      showedFile: null,
      croppedFile: null,
      status: LOAD_STATUSES.INACTIVE,
      previewable: false
    };
  },
  computed: {
    filesWithPreview() {
      return this.files.filter(f => f.preview);
    },

    allPreviews() {
      return this.files.filter(f => f.type === "image" && f.preview).map(f => f.preview);
    },

    croppedFileImage() {
      return this.croppedFile.preview;
    },

    hasPreviews() {
      return !!this.files.find(file => file.preview);
    },

    canShowProgressBar() {
      return this.status !== LOAD_STATUSES.INACTIVE;
    },

    computeProgressBar() {
      const all = this.files.length;
      const loaded = this.files.filter(file => file.status === LOAD_STATUSES.FINISH).length;
      const percentPerFile = 100 / all;

      return `calc(${percentPerFile}% * ${loaded})`;
    },

    isMultiple() {
      return this.allowedSize > 1;
    },

    getAcceptedTypes() {
      return this.allowedTypes.map(type => `.${type}`).join(", ");
    },

    formatSizeToMegabytes() {
      return (this.allowedSize / 1000).toFixed(2);
    },

    formatAcceptedFiles() {
      return this.allowedTypes.reduce((acc, value, i) => {
        let name;

        if (imageRegex.test(value)) name = "изображение";
        else name = "файл";

        if (acc.includes(name)) return acc;
        if (acc.length) acc += `, ${name}`;
        else acc += name;

        return acc;
      }, "");
    }
  },
  methods: {
    /**
     * @param {Event} ev
     */
    cancelEvent(ev) {
      ev.stopPropagation();
      ev.preventDefault();
    },

    // TODO: improve
    isShowable(file) {
      return imageRegex.test(file.source.type);
    },

    /**
     * @param {File} file
     * @return {boolean}
     */
    isAllowedType(file) {
      // TODO: improve
      return !!this.allowedTypes.find(type => file.type.includes(type));
    },

    isAllowedSize(file) {
      const kb = file.size / 1000;
      return kb > 0 && kb <= this.allowedSize;
    },

    isAllowedCount() {
      return this.files.length < this.allowedCount;
    },

    /**
     * @param {File} file
     * @return {boolean}
     */
    isDublicate(file) {
      return !!this.files.find(f => this.isFileEquals(file, f));
    },

    isFileEquals(file1, file2) {
      if (!file1) return false;
      if (!file2) return false;

      file1 = file1.source || file1;
      file2 = file2.source || file2;

      const checkSize = file1.size === file2.size;
      const checkLastModified = file1.lastModified === file2.lastModified;
      const checkType = file1.type === file2.type;

      return checkSize && checkLastModified && checkType;
    },

    /**
     * @param {File} file
     * @return {"image" | "other"}
     */
    getType(file) {
      switch (true) {
        case file.type.includes("image"):
          return "image";

        default:
          return "other";
      }
    },

    registerFile(file) {
      const payload = { source: file, id: ++nextFileId, status: LOAD_STATUSES.INACTIVE };
      this.files = this.files.concat(payload);

      return payload;
    },

    updateFile(file) {
      const copy = this.files.slice();

      this.files = copy.splice(
        copy.findIndex(f => f.id === file.id),
        1,
        file
      );

      this.files = copy;
    },

    unregisterFile(file) {
      this.files = this.files.filter(f => f.id !== file.id);
      if (this.isFileEquals(file, this.showedFile)) this.showedFile = null;
    },

    addToQueue(file) {
      this.queue.push(file);

      setTimeout(() => {
        if (!this.queueStart) {
          this.queueStart = true;
          this.nextFromQueue();
        }
      }, 0);
    },

    nextFromQueue() {
      const file = this.queue.shift();
      if (file) this.processFile(file);
      else this.queueStart = false;
    },

    processFile(file) {
      if (!this.isAllowedCount()) {
        this.nextFromQueue();
        console.error(`${file.name} weren't allowed due count limit`);
        return;
      }

      if (!this.isAllowedType(file)) {
        this.nextFromQueue();
        console.error(`${file.name} weren't allowed due mimetype`);
        return;
      }

      if (!this.isAllowedSize(file)) {
        this.nextFromQueue();
        console.error(`${file.name} weren't allowed due size`);
        return;
      }

      if (this.isDublicate(file)) {
        this.nextFromQueue();
        console.error(`${file.name} already loaded`);
        return;
      }

      switch (this.getType(file)) {
        case "image":
          return this.processImage(file);

        default:
          return this.processOther(file);
      }
    },

    processImage(file) {
      const reader = new FileReader();

      reader.onload = ev => {
        const img = new Image();

        img.onload = () => {
          const { width, height } = img;

          if (width < this.imageMinWidth) {
            this.nextFromQueue();
            console.error(`${file.name}: required minimum width is ${this.imageMinWidth}`);
            return;
          }

          if (height < this.imageMinHeight) {
            this.nextFromQueue();
            console.error(`${file.name}: required minimum height is ${this.imageMinHeight}`);
            return;
          }

          if (this.imageMaxWidth && width > this.imageMaxWidth) {
            this.nextFromQueue();
            console.error(`${file.name}: required maximum width is ${this.imageMaxWidth}`);
            return;
          }

          if (this.imageMaxHeight && height > this.imageMaxHeight) {
            this.nextFromQueue();
            console.error(`${file.name}: required maximum height is ${this.imageMaxHeight}`);
            return;
          }

          const registeredFile = this.registerFile(file);

          registeredFile.preview = ev.target.result;
          registeredFile.type = "image";

          this.updateFile(registeredFile);
          this.nextFromQueue();
        };

        img.src = ev.target.result;
      };

      reader.readAsDataURL(file);
    },

    /**
     * @param {File} file
     */
    processOther(file) {
      console.warn("need to handle this file:", file);
      this.nextFromQueue();
      // this.registerFile(file);
    },

    async upload(endpoint) {
      if (!this.files.length) {
        if (this.status !== LOAD_STATUSES.INACTIVE) this.status = LOAD_STATUSES.INACTIVE;
        return;
      }

      if (this.status !== LOAD_STATUSES.INACTIVE) return;
      this.status = LOAD_STATUSES.LOADING;

      const data = new FormData();
      const targets = [];

      for (const file of this.files) {
        if (file.status === LOAD_STATUSES.INACTIVE) {
          file.status = LOAD_STATUSES.LOADING;
          // TODO: consider what to send
          data.append(file.source.name, file.blob || file.source);
          targets.push(file);
        }
      }

      try {
        const response = await api.endpoint.post(endpoint, data, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });

        for (const file of targets) file.status = LOAD_STATUSES.FINISH;
        console.log(response);

        return true;
      } catch (error) {
        console.error(error);
        for (const file of targets) file.status = LOAD_STATUSES.ERROR;

        return false;
      } finally {
        this.status = LOAD_STATUSES.INACTIVE;
      }
    },

    onClick(ev) {
      if (this.files.length) {
        this.cancelEvent(ev);
        return;
      }
    },

    onClickRemove(file) {
      this.unregisterFile(file);
    },

    onClickStartShow(file) {
      this.showedFile = file;
    },

    onClickStopShow() {
      this.showedFile = null;
    },

    onClickPreview(ev, file) {
      if (ev.target !== ev.currentTarget) return;
      this.onClickStartShow(file);
    },

    onMouseEnterPreview(ev) {
      const closest = ev.currentTarget.closest(".preview__hover");

      if (ev.currentTarget !== closest) {
        this.previewable = false;
        return;
      }

      this.previewable = true;
    },

    onMouseLeavePreview(ev) {
      const closest = ev.currentTarget.closest(".preview__hover");

      if (ev.currentTarget !== closest) {
        if (ev.relatedTarget === closest) {
          this.previewable = true;
        }

        return;
      }

      this.previewable = false;
    },

    /**
     * @param {DragEvent} ev
     */
    onDrop(ev) {
      this.cancelEvent(ev);
      this.enter = false;

      if (!ev.dataTransfer.items) return;

      for (const meta of Array.from(ev.dataTransfer.items)) {
        if (meta.kind === "file") this.addToQueue(meta.getAsFile());
      }
    },

    onFileInputChange(ev) {
      this.cancelEvent(ev);

      if (!ev.currentTarget.files) return;

      for (const file of Array.from(ev.currentTarget.files)) {
        this.addToQueue(file);
      }
    },

    /**
     * @param {DragEvent} ev
     */
    onDragEnter(ev) {
      this.cancelEvent(ev);
      this.enter = true;
    },

    /**
     * @param {DragEvent} ev
     */
    onDragLeave(ev) {
      this.cancelEvent(ev);

      // check if we drag from drag child to main component
      if (ev.currentTarget === ev.relatedTarget) {
        return;
      }

      if (ev.relatedTarget) {
        // check if related target is a child of a main component
        switch (ev.relatedTarget.nodeType) {
          // if related target is a text
          case 3:
            {
              if (ev.relatedTarget.parentNode.closest(this.identifier)) return;
            }
            break;

          default:
            if (ev.relatedTarget.closest(this.identifier)) return;
        }
      }

      this.enter = false;
    },

    // TODO: maybe delete
    /**
     * @param {DragEvent} ev
     */
    onDragEnd(ev) {
      this.cancelEvent(ev);
      this.enter = false;
    },

    /**
     * @param {DragEvent} ev
     */
    onDragOver(ev) {
      this.cancelEvent(ev);
    },

    onClickStartCrop(file) {
      this.croppedFile = file;
    },

    onCropperExit() {
      this.croppedFile = null;
    },

    onCropperAccept(result) {
      this.croppedFile.blob = result;
      this.croppedFile.preview = URL.createObjectURL(result);
      this.updateFile(this.croppedFile);
      this.croppedFile = null;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~shared/modules/personal/styles/variables";

.drop-area {
  background-color: transparent;
  border: 1px dashed #d8d8d8;
  border-radius: 4px;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  min-width: 320px;
  width: 100%;
  font-family: Formular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  transition: background-color 0.3s ease;
  position: relative;
  color: #8a9aa3;

  &.hoverable:hover {
    background-color: rgba(#000, 0.05);
  }

  &.enter {
    background-color: rgba(#000, 0.05);
  }

  &.has-previews {
    display: block;
    padding: 0;
  }
}

.previews {
  lost-utility: clearfix;
  padding: 10px;
}

.preview {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  // width: 100%;
  height: 256px;
  display: block;
  box-shadow: 0 3px 6px rgba(#000, 0.1);
  border-radius: 4px;
  // margin: 10px;
  cursor: pointer;
  position: relative;

  &.col-2-4 {
    lost-column: 2/4 2;
    margin-top: 10px;
    lost-gutter: 10px;

    &:nth-child(2),
    &:first-child {
      margin-top: 0;
    }
  }
}

.preview__hover {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#000, 0.05);
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }
}

.preview__hover-toolbar {
  height: 50px;
  background-color: rgba(#eeeeee, 0.9);
  border-bottom: 1px dashed rgba(#000, 0.05);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.preview-icon {
  height: 100%;
  margin-left: 10px;
  fill: #202020;
  cursor: pointer;

  &:first-child {
    margin-left: 0;
  }

  & * {
    fill: #202020;
    transition: fill 0.3s ease;
  }

  &:hover * {
    fill: $color-primary;
  }
}

.preview-icon_zoom {
  height: 75%;
  position: relative;
  top: 1px;

  &.active * {
    fill: $color-primary;
  }
}

.preview-icon_crop {
  height: 70%;
  position: relative;
  top: -2px;
}

.file-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;

  &.hidden {
    visibility: hidden;
  }
}

.progress-bar {
  position: absolute;
  left: 0;
  bottom: -20px;
  width: 100%;
  height: 10px;
  overflow: hidden;
  border-radius: 3px;
  border: 1px solid rgba(#000, 0.1);
  opacity: 0;
  transition: opacity 0.3s ease;

  &.visible {
    opacity: 1;
  }
}

.progress-bar__load {
  transition: width 0.5s ease;
  background-color: $color-primary;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 0%;
}
</style>
