export function prettyNumber(value, symbol = "k") {
  const target = value.toString();

  switch (target.length) {
    case 4: {
      if (target[1] > 0) return target[0] + "." + target[1] + symbol;
      else return target[0] + symbol;
    }

    case 5: {
      return target.slice(0, 2) + symbol;
    }

    case 6: {
      return target.slice(0, 3) + symbol;
    }

    case 7: {
      if (target[1] > 0) return target[0] + "." + target[1] + symbol + symbol;
      else return target[0] + symbol + symbol;
    }

    case 8: {
      return target.slice(0, 2) + symbol + symbol;
    }

    case 9: {
      return target.slice(0, 3) + symbol + symbol;
    }

    case 10: {
      if (target[1] > 0) return target[0] + "." + target[1] + symbol + symbol + symbol;
      else return target[0] + symbol + symbol + symbol;
    }

    case 11: {
      return target.slice(0, 2) + symbol + symbol + symbol;
    }

    case 12: {
      return target.slice(0, 3) + symbol + symbol + symbol;
    }

    default:
      return target;
  }
}
