<template>
  <div ref="sidebar" class="sidebar">
    <div class="sidebar__container">
      <div class="sidebar__head">
        <logo class="sidebar__logo" />
        <div class="sidebar__title">
          Личный
          <br />Кабинет
        </div>
      </div>

      <router-link to="/profile" class="sidebar__profile">
        <slot>
          <div class="sidebar__avatar"></div>
          <div class="sidebar__name-container">
            <div class="sidebar__name">Имя</div>
            <div class="sidebar__subname">Чтото тут</div>
          </div>
        </slot>
      </router-link>

      <div class="sidebar__categories">
        <ul
          :key="i"
          v-for="(items, category, i) in groupedLinksByCategories"
          class="sidebar__category"
        >
          <div class="sidebar__category-name">{{ category }}</div>

          <ul class="sidebar__category-links">
            <li :key="i" v-for="(item, i) in items">
              <a v-if="item.external" :href="item.link" class="sidebar__category-link">
                {{
                item.name
                }}
              </a>
              <router-link class="sidebar__category-link" v-else :to="item.link">
                {{
                item.name
                }}
              </router-link>
            </li>
          </ul>
        </ul>

        <ul class="sidebar__category">
          <ul class="sidebar__category-links">
            <li :key="i" v-for="(item, i) in uncategorizedLinks">
              <a v-if="item.external" :href="item.link" class="sidebar__category-link">
                {{
                item.name
                }}
              </a>
              <router-link class="sidebar__category-link" v-else :to="item.link">
                {{
                item.name
                }}
              </router-link>
            </li>
          </ul>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "!vue-svg-loader!../images/briskly-logo.svg";

export default {
  props: {
    menu: {
      type: Array,
      required: true
    }
  },
  components: { Logo },
  data() {
    return {
      uncategorized: "uncategorized"
    };
  },
  computed: {
    groupedLinksByCategories() {
      return this.menu.reduce((acc, item) => {
        if (item.category) {
          if (!acc[item.category]) acc[item.category] = [];
          acc[item.category].push(item);
        }

        return acc;
      }, {});
    },

    uncategorizedLinks() {
      return this.menu.filter(item => !item.category);
    }
  },
  methods: {
    hide() {
      this.$refs.sidebar.classList.add("hide");
    }
  },
  mounted() {
    if (screen.width < 1024) this.hide();
  }
};
</script>

<style lang="scss" scoped>
.sidebar {
  width: 220px;
  min-width: 220px;
  height: 100vh;
  position: relative;
  z-index: 1000;
  transition: all 0.2s ease;
  background-color: #191b23;
}

.sidebar.hide {
  width: 0px;
  min-width: 0px;

  & .sidebar__container {
    width: 0px;
  }
}

.sidebar__container {
  width: inherit;
  height: inherit;
  left: 0;
  top: 0;
  background-color: #191b23;
  position: fixed;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  font-family: Formular, sans-serif;
  overflow: hidden;
}

.sidebar__head {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.sidebar__title {
  font-size: 12px;
  color: #fff;
  font-weight: 500;
  text-transform: lowercase;
  margin-left: 11px;
}

.sidebar__logo {
  width: 104px;
  height: 36px;
}

.sidebar__profile {
  height: 70px;
  border: 1px solid #2a3c46;
  border-left: none;
  border-right: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  text-decoration: none;
  transition: background-color 0.2s ease;
  cursor: pointer;

  &:hover,
  &.active {
    background-color: #2a3c46;
  }
}

.sidebar__name-container {
  margin-left: 10px;
}

.sidebar__name {
  font-weight: bold;
  font-size: 16px;
  line-height: 13px;
  color: #fff;
}

.sidebar__subname {
  font-size: 12px;
  line-height: 13px;
  color: #6d818c;
  font-style: normal;
  font-weight: normal;
  margin-top: 4px;
}

.sidebar__avatar {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 34px;
  height: 34px;
  background-color: #d6d6d6;
  border-radius: 50%;
}

.sidebar__categories {
  overflow-y: scroll;
  height: calc(100% - 150px);
  scrollbar-width: none;
  margin-top: 36px;

  &::-webkit-scrollbar {
    width: 0;
  }
}

.sidebar__category {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  padding: 0;
  list-style-type: none;

  letter-spacing: 0.04em;

  margin-bottom: 46px;
}

.sidebar__category-name {
  text-transform: uppercase;
  color: #596a74;
  padding-left: 16px;
}

.sidebar__category-links {
  margin: 0;
  padding: 0;
  margin-top: 15px;
  list-style-type: none;
}

.sidebar__category-link {
  text-decoration: none;
  color: #99a2ad;
  font-size: 15px;
  display: inline-block;
  padding: 15px 0;
  padding-left: 16px;
  width: 100%;
  transition: color 0.1s ease;
  position: relative;

  &.active::after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    height: 100%;
    width: 4px;
    background-color: #5fda3e;
  }

  &:hover {
    color: #fff;
  }
}
</style>
