export function splitPrice(value, sep = " ") {
  const target = value.toString();

  switch (target.length) {
    case 5: {
      return target.slice(0, 2) + sep + target.slice(2);
    }

    case 6: {
      return target.slice(0, 3) + sep + target.slice(3);
    }

    case 7: {
      return target.slice(0, 1) + sep + target.slice(1, 4) + sep + target.slice(4);
    }

    case 8: {
      return target.slice(0, 2) + sep + target.slice(2, 5) + sep + target.slice(5);
    }

    case 9: {
      return target.slice(0, 3) + sep + target.slice(3, 6) + sep + target.slice(6);
    }

    case 10: {
      return target.slice(0, 1) + sep + target.slice(1, 4) + sep + target.slice(4, 7) + sep + target.slice(7);
    }

    case 11: {
      return target.slice(0, 2) + sep + target.slice(2, 5) + sep + target.slice(5, 8) + sep + target.slice(8);
    }

    case 12: {
      return target.slice(0, 3) + sep + target.slice(3, 6) + sep + target.slice(6, 9) + sep + target.slice(9);
    }

    default: {
      return target;
    }
  }
}
