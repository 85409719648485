/* eslint-disable no-shadow, no-param-reassign */

const state = {
  phone: "",
  verification: "",
  stage: "phone",
  loading: false,
  authorizationKey: "",
  reAuthorizationTime: 0,
  reAuthorizationTimer: null
};

const getters = {
  phone: state => state.phone,
  verification: state => state.verification,
  stage: state => state.stage,
  loading: state => state.loading,
  authorizationKey: state => state.authorizationKey,
  reAuthorizationTime: state => state.reAuthorizationTime,
  reAuthorizationTimer: state => state.reAuthorizationTimer
};

const actions = {
  setPhoneAction: ({ commit }, value) => {
    commit("setPhone", value);
  },

  setVerificationAction: ({ commit }, value) => {
    commit("setVerification", value);
  },

  setStageAction: ({ commit }, value) => {
    commit("setStage", value);
  },

  setAuthorizationKeyAction: ({ commit }, value) => {
    commit("setAuthorizationKey", value);
  },

  startReAuthorizationTimerAction: ({ state, commit, dispatch }, value) => {
    dispatch("stopReAuthorizationTimerAction");
    commit("setReAuthorizationTime", value);

    const timer = setInterval(() => {
      if (!state.reAuthorizationTime) {
        dispatch("stopReAuthorizationTimerAction");
        return;
      }

      commit("setReAuthorizationTime", state.reAuthorizationTime - 1);
    }, 1000);

    commit("setReAuthorizationTimer", timer);
  },

  stopReAuthorizationTimerAction: ({ state }) => {
    clearInterval(state.reAuthorizationTimer);
  },

  setLoadingAction: ({ commit }, value) => {
    commit("setLoading", value);
  }
};

const mutations = {
  setPhone: (state, value) => {
    state.phone = value;
  },

  setVerification: (state, value) => {
    state.verification = value;
  },

  setStage: (state, value) => {
    state.stage = value;
  },

  setLoading: (state, value) => {
    state.loading = value;
  },

  setAuthorizationKey: (state, value) => {
    state.authorizationKey = value;
  },

  setReAuthorizationTime: (state, value) => {
    state.reAuthorizationTime = value;
  },

  setReAuthorizationTimer: (state, value) => {
    state.reAuthorizationTimer = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
