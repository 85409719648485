<template>
  <div @click="onClickContainer" class="images-preview">
    <img @click="onClickImage" class="current-image" :src="curr" />
    <close-icon @click="exit" class="preview-icon preview-icon_close" />
  </div>
</template>

<script>
import CloseIcon from '!vue-svg-loader!shared/modules/personal/images/close-icon.svg';

export default {
  components: {
    CloseIcon,
  },
  props: {
    images: {
      type: Array,
      required: false,
      default: () => [],
    },
    current: {
      type: String,
      required: true,
    },
    exit: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      curr: this.current,
    };
  },
  computed: {
    content() {
      return this.images.filter(image => image !== this.current).concat(this.current);
    },
  },
  methods: {
    onKeyExit(ev) {
      if (ev.keyCode !== 27) return;
      this.exit();
    },
    onClickImage(ev) {
      const index = this.content.findIndex(image => image === this.curr);
      const nextCurrent = this.content[index + 1];

      if (nextCurrent) this.curr = nextCurrent;
      else this.curr = this.content[0];
    },
    onClickContainer(ev) {
      if (ev.target !== ev.currentTarget) return;
      this.exit();
    },
  },
  mounted() {
    document.addEventListener('keydown', this.onKeyExit);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.onKeyExit);
  },
};
</script>

<style lang="scss" scoped>
@import "~shared/modules/personal/styles/variables";

.images-preview {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(#000, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    max-height: 100vh;
    max-width: 100vw;
    cursor: pointer;
  }

  & .preview-icon_close {
    position: absolute;
    top: 20px;
    right: 20px;
    height: 32px;
  }
}

.preview-icon {
  height: 100%;
  margin-left: 10px;
  fill: #fff;
  cursor: pointer;

  &:first-child {
    margin-left: 0;
  }

  & * {
    fill: #fff;
    transition: fill 0.3s ease;
  }
}
</style>
