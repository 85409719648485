import { checkAuth } from "./guards/checkAuth";
import $auth from "./store/auth";
import routes from "./routes";

export function connect({ store, router } = {}) {
  if (store) {
    store.registerModule("$auth", $auth);
  }

  if (router) {
    router.addRoutes(routes);
    router.beforeEach(checkAuth);
  }
}
