<template>
  <div class="select-input">
    <input
      ref="field"
      type="text"
      @input="onChange"
      :value="value"
      class="select-input__field"
      :style="{ 'padding-left': getPaddingLeft }"
    />

    <div @click="onClickValue" v-show="showValues" ref="values" class="select-input__values">
      <slot name="values"></slot>
    </div>

    <div @click="onClickSelector" ref="selector" class="select-input__selector">
      <div ref="selected-value" class="select-input__selected-value"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    onOption: {
      type: Function,
      required: true
    },
    onChange: {
      type: Function,
      required: true
    },
    value: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      paddingLeft: 0,
      showValues: false,
      selectedOption: null
    };
  },
  computed: {
    getPaddingLeft() {
      return `${this.paddingLeft}px`;
    }
  },
  methods: {
    computePaddingLeft() {
      const { width } = this.$refs.selector.getBoundingClientRect();
      this.paddingLeft = width;
    },

    initDefaultOption() {
      const { values } = this.$refs;
      const target = values.querySelector("[default]") || values.children[0];
      this.selectedOption = target.getAttribute("option");
    },

    renderSelectedOption() {
      const { values, ["selected-value"]: selectedValue } = this.$refs;

      const target = Array.from(values.children).find(
        elem => this.selectedOption === elem.getAttribute("option")
      );

      const fragment = document.createDocumentFragment();

      Array.from(target.children)
        .filter(v => v.hasAttribute("for-selected-value"))
        .forEach(elem => {
          const cloned = elem.cloneNode(true);
          this.showElem(cloned);
          fragment.appendChild(cloned);
        });

      selectedValue.innerHTML = "";
      selectedValue.appendChild(fragment);

      this.computePaddingLeft();
    },

    cleanUpValues() {
      Array.from(
        this.$refs.values.querySelectorAll("[for-selected-value]")
      ).forEach(elem => {
        if (elem.hasAttribute("for-values")) return;
        this.hideElem(elem);
      });
    },

    hideElem(elem) {
      elem.style.visibility = "hidden";
      elem.style.zIndex = -999;
      elem.style.position = "absolute";
    },

    showElem(elem) {
      elem.style.visibility = null;
      elem.style.zIndex = null;
      elem.style.position = null;
    },

    // hide selector values when click wasn't on selector element and its children
    onClick(ev) {
      const targetSelector = ".select-input";

      if (!ev.target) return;

      if (!ev.target.closest) {
        if (!ev.target.parentNode.closest(targetSelector)) {
          this.showValues = false;
        }
      }

      if (!ev.target.closest(targetSelector)) {
        this.showValues = false;
      }
    },

    onClickSelector(ev) {
      this.showValues = !this.showValues;
    },

    onClickValue(ev) {
      if (ev.target === ev.currentTarget) return;

      const target = ev.target.closest(".select-input__values > *");
      if (!target) return;

      this.selectedOption = target.getAttribute("option");
      this.showValues = false;
      this.renderSelectedOption();
      this.onOption(this.selectedOption);
    }
  },
  mounted() {
    this.initDefaultOption();
    this.renderSelectedOption();
    this.cleanUpValues();
    document.addEventListener("click", this.onClick);
  }
};
</script>

<style lang="scss" scoped>
.select-input {
  position: relative;
  font-family: Formular;
  font-size: 16px;
  font-weight: 400;
  color: #202020;
  z-index: 1000;
  user-select: none;
}

.select-input__field {
  padding: 10px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Formular;
  font-size: 16px;
  font-weight: 400;
  color: #99a2ad;
}

.select-input__selector {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
}

.select-input__selected-value {
  display: flex;
  align-items: center;
}

.select-input__values {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  background-color: #fff;
  box-shadow: 0 5px 5px rgba(#000, 0.1);
  width: 100%;
  max-height: 200px;
  overflow-y: auto;

  scrollbar-color: #c5c5c5 #fff;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 5px;
    background-color: #fff;
    border: 1px solid rgba(black, 0.03);
  }

  &::-webkit-scrollbar-thumb {
    background: #c5c5c5;
  }

  // option element
  & > * {
    padding: 15px;

    &:hover,
    &.active {
      background-color: rgba(#000, 0.03);
    }
  }
}
</style>
