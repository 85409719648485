import { api } from "shared/core";

export function checkAuth(to, from, next) {
  if (to.name === "auth") {
    return next();
  }

  if (to.name === "logout") {
    api.endpoint
      .logout()
      .then(() => next({ name: "auth" }))
      .catch(e => console.error(e));

    return;
  }

  const { access_token } = api.endpoint.getTokens();

  if (!access_token) {
    return next({ name: "auth" });
  }

  next();
}
