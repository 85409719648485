<template>
  <div>
    <div ref="container" class="container">
      <div ref="viewport"></div>

      <div class="buttons">
        <check-icon @click="onAccept" class="button button_accept" />
        <close-icon @click="onExit" class="button button_exit" />
      </div>
    </div>
  </div>
</template>

<script>
import "croppie/croppie.css";
import Croppie from "croppie";
import CloseIcon from "!vue-svg-loader!shared/modules/personal/images/close-icon.svg";
import CheckIcon from "!vue-svg-loader!shared/modules/personal/images/check-mark-outlined-icon.svg";

export default {
  components: {
    CloseIcon,
    CheckIcon
  },
  props: {
    value: {
      type: String,
      required: true
    },
    accept: {
      type: Function,
      required: true
    },
    exit: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      instance: null
    };
  },
  methods: {
    async onAccept() {
      const result = await this.instance.result("blob");
      this.accept(result);
    },
    onExit() {
      this.exit();
    }
  },
  mounted() {
    const img = new Image();

    img.src = this.value;

    img.onload = () => {
      let { width, height } = img;

      if (width > window.innerWidth) width = window.innerWidth;
      if (height > window.innerHeight) height = window.innerHeight;

      this.instance = new Croppie(this.$refs.viewport, {
        showZoomer: false,
        enableOrientation: false,
        viewport: {
          width: Math.ceil(width / 1.5),
          height: Math.ceil(height / 1.5)
        }
      });

      this.instance.bind({ url: this.value });
    };
  }
};
</script>

<style lang="scss" scoped>
@import "~shared/modules/personal/styles/variables";

.container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  overflow: hidden;
}

.buttons {
  z-index: 2;
  position: fixed;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
}

.button {
  cursor: pointer;
  margin-left: 10px;
  width: 32px;
  height: 32px;

  & * {
    fill: #fff;
    transition: fill 0.2s ease;
  }

  &:first-child {
    margin-left: 0;
  }

  &.button_accept:hover * {
    fill: $color-primary;
  }

  &.button_exit:hover * {
    fill: lightcoral;
  }
}
</style>
