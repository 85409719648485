<template>
  <div class="main">
    <sidebar :menu="menu" />

    <div class="content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Sidebar from "../components/Sidebar";

export default {
  props: {
    menu: {
      type: Array,
      required: true
    }
  },
  components: {
    Sidebar
  }
};
</script>

<style lang="scss" scoped>
.main {
  display: flex;
}

.content {
  width: 100%;
  min-height: 100vh;
  background-color: #f0f3f8;
}
</style>
