import { OK, NO_RESPONSE, REPEAT, NOT_AUTHORIZED, ERROR } from "./constants";
import bus from "shared/core/bus";

const accessTokenName = `${PROJECT_NAME}_access_token`;
const refreshTokenName = `${PROJECT_NAME}_refresh_token`;

export function addAccessToken(params) {
  if (!params.headers) params.headers = {};

  const { access_token } = this.getTokens();
  if (!access_token) return params;

  params.headers["Authorization"] = `Bearer ${access_token}`;

  return params;
}

/**
 *
 * @param {{ access_token: string, refresh_token: string }} data
 */
export function saveTokens(data = {}) {
  const { access_token, refresh_token } = data;

  if (access_token && refresh_token) {
    localStorage.setItem(accessTokenName, access_token);
    localStorage.setItem(refreshTokenName, refresh_token);
  }
}

/**
 * @return {{access_token: string, refresh_token: string}}
 */
export function getTokens() {
  const tokens = {
    access_token: localStorage.getItem(accessTokenName),
    refresh_token: localStorage.getItem(refreshTokenName)
  };

  return tokens;
}

export function deleteTokens() {
  localStorage.removeItem(accessTokenName);
  localStorage.removeItem(refreshTokenName);
}

/**
 *
 * @param {string} phone
 */
export async function sendPhoneNumber(phone) {
  try {
    const response = await this.httpAdapter.post(
      this.uri.logIn,
      { phone },
      { withCredentials: true }
    );
    return response.data;
  } catch (error) {
    if (error && error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }

    return Promise.reject(ERROR);
  }
}

/**
 *
 * @param {string} verificationCode
 * @param {string} key
 */
export async function sendVerificationCode(verificationCode, key) {
  try {
    const response = await this.httpAdapter.post(
      this.uri.logInConfirm,
      {
        confirmation_code: verificationCode,
        key
      },
      { withCredentials: true }
    );

    saveTokens(response.data.tokens);
    bus.emit("authInit");
  } catch (error) {
    if (error && error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }

    return Promise.reject(ERROR);
  }
}

export async function logout() {
  try {
    await this.httpAdapter.post(this.uri.logOut, {}, { withCredentials: true });
    deleteTokens();
    bus.emit("authLogout");
  } catch (error) {
    if (error && error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }

    return Promise.reject(ERROR);
  }
}

export function checkVersion(payload) {
  if (payload && typeof payload.version !== "undefined") {
    if (localStorage.getItem("version") !== version) {
      localStorage.setItem("version", version);

      if (document) {
        document.location.reload(true);
      }
    }
  }
}

export async function checkTokens(status) {
  if (status === 401) {
    try {
      const { refresh_token } = this.getTokens();

      const params = {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${refresh_token}`
        }
      };

      const res = await this.httpAdapter.post(this.uri.refreshToken, {}, params);
      this.saveTokens(res.data.tokens);
      bus.emit("authRefresh");

      return { type: REPEAT };
    } catch (error) {
      if (error && error.response) {
        switch (error.response.status) {
          case 401: {
            bus.emit("authLogout");
            return { type: NOT_AUTHORIZED };
          }

          default:
            return { type: NO_RESPONSE };
        }
      }

      return { type: ERROR };
    }
  }

  return { type: OK };
}
