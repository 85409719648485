export default [
  {
    path: "/auth",
    name: "auth",
    component: () => import("./views/Auth")
  },

  {
    path: "/logout",
    name: "logout",
    component: () => import("./views/Logout")
  }
];
