<template>
  <input :value="value" @input="onInput" class="field" />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ""
    }
  },
  methods: {
    onInput(ev) {
      this.$emit("field-input", ev);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/variables";

.field {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  padding: 8px 16px;
  color: #000;
  font-family: Formular, sans-serif;
  font-size: 12px;
  font-weight: 500;
  width: 100%;
}

.field:disabled {
  background-color: #efefef;
}

.field::placeholder {
  color: #a8a8a8;
}

.field.success {
  border-color: $color-primary;
}
</style>
