<template>
  <div class="toolbar">
    <div class="main">
      <div @click="onClickMenu" class="menu">
        <menu-burger-icon class="menu-burger-icon" />
      </div>

      <slot name="main">
        <div class="breadcrumbs">
          <div class="breadcrumb-container" :key="i" v-for="(link, i) in links">
            <router-link :to="link.path" class="breadcrumb">
              <arrow-icon class="breadcrumb-arrow breadcrumb-arrow_mobile"></arrow-icon>
              {{ localizeLinkName(link.name) }}
            </router-link>
            <arrow-icon class="breadcrumb-arrow"></arrow-icon>
          </div>
        </div>
      </slot>
    </div>

    <div class="buttons">
      <slot name="buttons">
        <mail-icon class="button" />
        <bell-icon class="button" />
        <question-icon class="button" />
      </slot>
    </div>
  </div>
</template>

<script>
import MenuBurgerIcon from "!vue-svg-loader!../images/menu-burger-icon.svg";
import MailIcon from "!vue-svg-loader!../images/mail-icon.svg";
import BellIcon from "!vue-svg-loader!../images/bell-icon.svg";
import QuestionIcon from "!vue-svg-loader!../images/question-icon.svg";
import ArrowIcon from "!vue-svg-loader!../images/arrow-icon.svg";

export default {
  components: {
    MailIcon,
    BellIcon,
    QuestionIcon,
    MenuBurgerIcon,
    ArrowIcon
  },
  computed: {
    links() {
      return this.$router.currentRoute.path
        .split("/")
        .map(partOfURI => {
          if (!partOfURI) return "/";
          return partOfURI;
        })
        .map(partOfURI => {
          let path = partOfURI;

          if (partOfURI !== "/") {
            const foundIndex = this.$router.currentRoute.path.indexOf(
              partOfURI
            );

            if (foundIndex !== -1) {
              path = this.$router.currentRoute.path.slice(
                0,
                foundIndex + partOfURI.length
              );
            }
          }

          return {
            path,
            name: decodeURIComponent(partOfURI)
          };
        });
    }
  },
  methods: {
    onClickMenu() {
      const menu = document.querySelector(".sidebar");
      if (menu) menu.classList.toggle("hide");
    },
    localizeLinkName(name) {
      switch (name) {
        case "/":
          return "Главная";

        case "stores":
          return "Магазины";

        case "companies":
          return "Компании";

        case "documents":
          return "Документы";

        case "staff":
          return "Сотрудники";

        case "map":
          return "Карта";

        default:
          return name;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/variables";

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.toolbar.outline {
  background-color: transparent;
  box-shadow: none;
}

.buttons {
  display: flex;
  align-items: center;
}

.button {
  width: 20px;
  height: 20px;
  margin: 0 10px;
  cursor: pointer;

  &:hover > * {
    fill: #000;
  }

  & > * {
    transition: fill 0.2s ease;
  }
}

.main {
  display: flex;
  align-items: center;
}

.menu-burger-icon {
  width: 14px;
  height: 14px;
  cursor: pointer;
  margin-right: 18px;

  & > * {
    transition: fill 0.2s ease;
  }

  &:hover > * {
    fill: $color-primary;
  }
}

.breadcrumbs {
  display: flex;
  align-items: center;
}

.breadcrumb {
  color: #99a2ad;
  font-family: Formular;
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 0.01em;
  position: relative;
  margin-left: 5px;
  font-weight: 500;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  text-decoration: none;
  background-color: #fff;
  padding: 8px 20px;
  color: #000;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

.breadcrumb-container {
  display: flex;
  align-items: center;
}

.breadcrumb-arrow {
  width: 12px;
  height: 12px;
  margin: 0 10px;
}

.breadcrumb-container:last-of-type .breadcrumb {
  color: #99a2ad;
  background-color: transparent;
  box-shadow: none;
  font-weight: 400;
  padding: 0;
}

.breadcrumb-container:last-of-type .breadcrumb-arrow {
  display: none;
}

.breadcrumb-arrow_mobile {
  display: none;
}

@media (max-width: 600px) {
  .breadcrumb-container:not(:first-child) {
    display: none;
  }

  .breadcrumb-container > .breadcrumb-arrow {
    display: none;
  }

  .breadcrumb-arrow_mobile {
    display: inline-block;
    margin: 0;
    transform: rotate(180deg);
  }

  .breadcrumb {
    padding: 8px 16px;
  }
}
</style>
