<template>
  <div id="app" class="container">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},

  mounted() {},

  methods: {},
};
</script>
