// 2019-12-23
const regex = /^[0-9]{4,4}-([0][0-9]{1,1}|[1][0-2]{1,1})-[0-9]{2,2}$/

const patterns = {
  "en-US": "yyyy.mm.dd",
  "ru-RU": "dd.mm.yyyy"
}

const defaultLocale = "en-US";

export function localeDate(date, locale = defaultLocale) {
  if (!regex.test(date)) return date;

  const [year, month, day] = date.split("-");

  let target = patterns[locale];
  if (!target) target = patterns[defaultLocale];

  target = target.replace("yyyy", year);
  target = target.replace("mm", month);
  target = target.replace("dd", day);

  return target;
}
