import { backOffice } from "./backOffice";
import { business } from "./business";
import { company } from "./company";
import { checker } from "./checker";

let endpoint;
const endpoints = { backOffice, business, company, checker };

// Даём возможность ссылаться с одного эндпоинта на другой
// business.backOffice, backOffice.business
for (const endpointName in endpoints) {
  if (endpoints.hasOwnProperty(endpointName)) {
    const shared = { ...endpoints };

    for (const innerKey in endpoints[endpointName]) {
      Object.assign(endpoints[endpointName][innerKey], shared);
    }
  }
}

/**
 *
 * @param {string} endpointName
 */
function setEndpoint(endpointName) {
  const [root, newEndpoint] = endpointName.split("/");
  endpoint = endpoints[root][newEndpoint];
}

export { endpoint, endpoints, setEndpoint };
