import { api } from "shared/core";
import store from "@/store";

export function fetchProfile(to, from, next) {
  setTimeout(() => {
    if (!store.getters["$profile/profile"].id && !store.getters["$profile/loading"]) {
      store.dispatch("$profile/setLoadingAction", true);

      // TODO: use api directly from endpoint
      api.endpoint.backOffice.administrator
        .get("dashboard/profile/get")
        .then(profile => store.dispatch("$profile/setProfileAction", profile))
        .catch(() => {})
        .finally(() => store.dispatch("$profile/setLoadingAction", false));
    }
  }, 0);

  next();
}
