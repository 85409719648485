import axios from "axios";
import { NO_RESPONSE, REPEAT, NOT_AUTHORIZED, ERROR } from "./constants";
import bus from "shared/core/bus";
import merge from "lodash/merge";

let isAuthenticationChecked = false;

function transformBodyIntoQuery(jsonData) {
  const entries = Object.entries(jsonData);

  if (!entries.length) return;

  return entries.map(x => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`).join("&");
}

/**
 *
 * @param {string} path
 * @param {object} body
 */
export async function get(path, body = {}, opts = {}) {
  if (!path.startsWith("/")) path = "/" + path;

  const query = transformBodyIntoQuery(body);
  const uri = query ? path + "?" + query : path;

  const params = _handleOptions(this.addAccessToken({ withCredentials: true }), opts);

  try {
    const response = await this.httpAdapter.get(this.uri.root + uri, params);
    return this._handleResponse({ executor: "get", path, body, response });
  } catch (error) {
    return this._handleResponse({ executor: "get", path, body, response: error.response });
  }
}

/**
 *
 * @param {string} path
 * @param {object} body
 */
export async function post(path, body = {}, opts = {}) {
  if (!path.startsWith("/")) path = "/" + path;

  const params = _handleOptions(this.addAccessToken({ withCredentials: true }), opts);

  try {
    const response = await this.httpAdapter.post(this.uri.root + path, body, params);
    return this._handleResponse({ executor: "post", path, body, response });
  } catch (error) {
    return this._handleResponse({ executor: "post", path, body, response: error.response });
  }
}

/**
 *
 * @param {string} path
 * @param {object} body
 */
export async function update(path, body = {}, opts = {}) {
  if (!path.startsWith("/")) path = "/" + path;

  const params = _handleOptions(this.addAccessToken({ withCredentials: true }), opts);

  try {
    const response = await this.httpAdapter.patch(this.uri.root + path, body, params);
    return this._handleResponse({ executor: "update", path, body, response });
  } catch (error) {
    return this._handleResponse({ executor: "update", path, body, response: error.response });
  }
}

/**
 *
 * @param {string} path
 * @param {object} body
 */
export async function remove(path, body = {}, opts) {
  if (!path.startsWith("/")) path = "/" + path;

  const params = _handleOptions(this.addAccessToken({ withCredentials: true }), opts);

  try {
    const response = await this.httpAdapter.delete(this.uri.root + path, body, params);
    return this._handleResponse({ executor: "remove", path, body, response });
  } catch (error) {
    return this._handleResponse({ executor: "remove", path, body, response: error.response });
  }
}

function _handleOptions(source = {}, options = {}) {
  return merge({}, options, source);
}

export async function _handleResponse({ executor, path, body, response }) {
  try {
    const { data, status } = response;

    this.checkVersion(data);

    const { type } = await this.checkTokens(status);

    if (IS_DEV) console.info(`[${type} ${status}] ${path}`);

    switch (type) {
      case NOT_AUTHORIZED:
        return Promise.reject(NOT_AUTHORIZED);

      case NO_RESPONSE:
        return Promise.reject(NO_RESPONSE);

      case ERROR:
        return Promise.reject(ERROR);

      case REPEAT:
        return this[executor](path, body);
    }

    if (!isAuthenticationChecked) {
      isAuthenticationChecked = true;
      bus.emit("authInit");
    }

    return status < 400 ? data : Promise.reject(data);
  } catch (error) {
    console.error(error);
    return Promise.reject(ERROR);
  }
}

export { axios as httpAdapter };
