/* eslint-disable no-shadow, no-param-reassign */

const state = {
  loading: false,
  profile: {},
};

const getters = {
  loading: state => state.loading,
  profile: state => state.profile,
};

const actions = {
  setLoadingAction: ({ commit }, value) => {
    commit('setLoading', value);
  },

  setProfileAction: ({ commit }, value) => {
    commit('setProfile', value);
  },
};

const mutations = {
  setLoading: (state, value) => {
    state.loading = value;
  },

  setProfile: (state, value) => {
    state.profile = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
