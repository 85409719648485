function computeSuffixForOneLength(target) {
  if (/^[056789]{1,1}$/.test(target)) return " штук";
  else if (/^[234]{1,1}$/.test(target)) return " штуки";
  else return " штука";
}

export function amountSuffix(value) {
  const target = value.toString();

  if (target.length === 1) {
    return target + computeSuffixForOneLength(target);
  }

  const lastTwoDigits = target.slice(target.length - 2);

  switch (lastTwoDigits[0]) {
    case "1": return target + " штук";
    default: return target + computeSuffixForOneLength(lastTwoDigits[1]);
  }
}
