import 'animate.css';
import 'normalize.css';
import '@/styles.scss';
import 'regenerator-runtime';

import Vue from 'vue';
import Vuex from 'vuex';
import { api } from 'shared/core';
import { auth, profile, personal } from 'shared/modules';
import App from '@/App';
import router from '@/router';
import store from '@/store';
import channel from '@/channel';
import mainEvent from '@/events/main';

Vue.config.productionTip = false;
Vue.prototype.$api = api;
Vue.use(Vuex);

channel.init();
mainEvent.init(store);

api.setEndpoint('checker/main');
personal.connect();
auth.connect({ store, router });
profile.connect({ store, router });

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
