<template>
  <div class="container">
    <div class="steps">
      <div class="head">
        <slot name="head">Заголовок</slot>
        <close-icon class="close" @click="close" />
      </div>

      <div ref="sections" class="sections">
        <div class="sections__items">
          <div :key="i" v-for="(step, i) in values" class="section">
            <div
              :class="{ section__arrow: true, active: isActive(i), finished: isFinished(i) }"
            ></div>
            <div @click="onChangeStep(i)" class="section__text">{{ step.name }}</div>
          </div>
        </div>

        <div v-if="!!values[curr].title" class="sections__title">{{ values[curr].title }}</div>
      </div>

      <div ref="body" class="body">
        <div :key="i" v-for="(step, i) in values">
          <div v-if="canRender(i)">
            <slot :name="i"></slot>
          </div>
        </div>
      </div>

      <div ref="footer" class="footer">
        <slot name="footer">
          <btn class="btn_footer" @click.native="onClickNextStep">
            {{ getButtonText() }}
          </btn>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import Btn from "./Btn";
import CloseIcon from "!vue-svg-loader!shared/modules/personal/images/close-icon.svg";

export default {
  components: {
    Btn,
    CloseIcon
  },
  props: {
    values: {
      type: Array,
      required: true
    },
    current: {
      type: Number,
      required: false,
      default: 0
    },
    close: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      state: {},
      curr: this.current
    };
  },
  methods: {
    canRender(i) {
      return i === this.curr;
    },
    isActive(step) {
      return step === this.curr;
    },
    isFinished(step) {
      return this.state[step] === true;
    },
    isLast() {
      return this.curr === this.values.length - 1;
    },
    getButtonText() {
      if (this.values[this.curr].button) {
        return this.values[this.curr].button;
      }

      if (this.isLast()) {
        return "Готово";
      }

      return "Сохранить и продолжить";
    },
    async onClickNextStep() {
      const canContinue = await this.values[this.curr].handler();

      if (!canContinue) return;

      this.state[this.curr] = true;
      if (this.curr < this.values.length - 1) this.curr = this.curr + 1;
    },
    onChangeStep(to) {
      if (to === this.values.length - 1) return;

      // if previous step was already finished then approve change
      if (this.state[to - 1]) {
        this.curr = to;
        return;
      }

      if (!this.state[to]) return;

      this.curr = to;
    },
    maybeAddShadow() {
      const { sections, body, footer } = this.$refs;

      // if has scroll
      if (body.scrollHeight > body.clientHeight) {
        if (!sections.classList.contains("shadow")) sections.classList.add("shadow");
        if (!footer.classList.contains("shadow")) footer.classList.add("shadow");
      } else {
        if (sections.classList.contains("shadow")) sections.classList.remove("shadow");
        if (footer.classList.contains("shadow")) footer.classList.remove("shadow");
      }
    }
  },
  mounted() {
    this.maybeAddShadow();
  },
  updated() {
    this.maybeAddShadow();
  }
};
</script>

<style lang="scss" scoped>
.container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1100;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(#000, 0.6);
}

.steps {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  width: 100%;
  max-width: 700px;
  min-width: 320px;
  background-color: #fff;
  max-height: 100vh;
  z-index: 1000;
  overflow-x: hidden;
}

.head {
  padding: 20px;
  font-family: Formular;
  font-weight: bold;
  font-size: 24px;
  color: #202020;
  position: relative;
  padding-right: 60px;
  text-transform: capitalize;
}

.body {
  overflow-y: auto;
  max-height: 50vh;
}

.footer {
  padding-top: 10px;
  padding-bottom: 30px;

  &.shadow {
    position: relative;
    padding-top: 20px;
    box-shadow: 0 -5px 5px rgba(#000, 0.05);
    z-index: 100;
  }
}

.close {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.sections {
  margin-bottom: 20px;

  &.shadow {
    position: relative;
    margin-bottom: 0;
    padding-bottom: 20px;
    box-shadow: 0 5px 5px rgba(#000, 0.05);
    z-index: 100;
  }
}

.sections__items {
  display: flex;
}

.sections__title {
  margin-top: 40px;
  margin-left: 20px;
  font-family: Formular;
  font-weight: normal;
  font-size: 16px;
  color: #202020;
}

.section {
  width: 100%;
  max-width: 100%;

  &:first-child .section__text {
    margin-left: 20px;
  }

  &:last-child .section__text {
    border-bottom-color: transparent;
    cursor: default;
  }

  &:last-child .section__arrow::after {
    display: none;
  }
}

.section__arrow {
  height: 12px;
  background-color: #d8d8d8;
  margin-bottom: 10px;
  position: relative;

  &::after {
    content: "";
    z-index: 2;
    position: absolute;
    right: -5px;
    top: 0;
    height: 100%;
    width: 10px;
    background-color: #fff;
    clip-path: polygon(42% 0, 100% 49%, 44% 100%, 0% 100%, 54% 48%, 0% 0%);
  }

  &.finished {
    background-color: #57d435;
  }

  &.current {
    background-color: #fce07e;
  }

  &.active {
    background-color: #fce07e;
  }

  &.active ~ .section__text {
    font-weight: bold;
    border-bottom-color: transparent;
  }
}

.section__text {
  font-family: Formular;
  font-size: 16px;
  color: #202020;
  display: inline;
  border-bottom: 2px dotted #202020;
  cursor: pointer;
  user-select: none;
}

.btn_footer {
  margin-left: 20px;
  padding-left: 40px;
  padding-right: 40px;
}
</style>
