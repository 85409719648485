<template>
  <div class="search">
    <input type="text" class="search__field" placeholder="начните вводить" />
    <div class="search__btn">
      <search-icon class="search__icon" />
    </div>
  </div>
</template>

<script>
import SearchIcon from "!vue-svg-loader!../images/search-icon.svg";

export default {
  components: {
    SearchIcon
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/variables";

.search {
  position: relative;
}

.search__field {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  font-size: 12px;
  padding: 9px 28px 9px 12px;
  font-weight: 500;
  font-family: Formular, sans-serif;
  width: 100%;
}

.search__field::placeholder {
  color: #a8a8a8;
}

.search__btn {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 32px;
  cursor: pointer;

  &:hover .search__icon > * {
    fill: $color-primary;
  }
}

.search__icon {
  width: 14px;
  height: 14px;

  & > * {
    transition: all 0.2s ease;
  }
}
</style>
