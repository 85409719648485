import { render, staticRenderFns } from "./Spreadsheet.vue?vue&type=template&id=5547e949&scoped=true&"
var script = {}
import style0 from "./Spreadsheet.vue?vue&type=style&index=0&id=5547e949&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../briskly-vue-cli/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5547e949",
  null
  
)

export default component.exports