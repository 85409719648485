import { render, staticRenderFns } from "./Btn.vue?vue&type=template&id=360b8867&scoped=true&"
var script = {}
import style0 from "./Btn.vue?vue&type=style&index=0&id=360b8867&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../briskly-vue-cli/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "360b8867",
  null
  
)

export default component.exports