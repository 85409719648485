import { api, bus } from 'shared/core';

class Channel {
  _loading = false;

  _instance;

  _channelKey;

  init() {
    bus.on('authInit', this._connect);
    bus.on('authRefresh', this._connect);
    bus.on('authLogout', this._disconnect);
  }

  _connect = async () => {
    if (this._instance) return;
    if (this._loading) return;

    try {
      this._loading = true;

      const response = await api.endpoint.get('/dashboard/profile/get-channel/');

      if (response && response.channel_key) {
        this._channelKey = response.channel_key;
        this._instance = new WebSocket('wss://appsocket.briskly.online');
        this._subscribe();
      }
    } catch {
    } finally {
      this._loading = false;
    }
  };

  _disconnect = () => {
    this._unsubscribe();
    this._instance = null;
  };

  _subscribe = () => {
    if (this._instance) {
      this._instance.addEventListener('open', this._onOpen);
      this._instance.addEventListener('error', this._onError);
      this._instance.addEventListener('message', this._onMessage);
    }
  };

  _unsubscribe = () => {
    if (this._instance) {
      this._instance.removeEventListener('open', this._onOpen);
      this._instance.removeEventListener('error', this._onError);
      this._instance.removeEventListener('message', this._onMessage);
    }
  };

  _destroyOnTimeout = () => {
    if (this._instance && this._instance.readyState !== 1) {
      this._unsubscribe();
      this._instance = null;
    }
  };

  _onError = error => {
    console.error(error);
  };

  _onOpen = () => {
    this._instance.send(JSON.stringify({ auth: this._channelKey }));
  };

  _onMessage = ev => {
    try {
      const message = JSON.parse(ev.data);

      if (message && message.data && message.data.order) {
        return bus.emit('order', message.data.order);
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export default new Channel();
